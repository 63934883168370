<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-text class="py-5 px-7">
      <p class="card-title mb-0 black--text ml-3">Buttons</p>
      <v-container>
        <v-row
          class="d-flex flex-column align-center container-style py-2 my-3 mx-1 px-7"
        >
          <v-col
            class="d-flex justify-space-between flex-wrap"
            cols="auto"
            xl="8"
          >
            <!-- Default btn with icon -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Default w/ Icon</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Default w/ Icon')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div>
                <!-- Btn -->
                <ButtonWithIcon
                  class="default-btn"
                  icon="mdi-account-plus"
                  :text="'Action'"
                  @click="btnClicked('Default w/ Icon')"
                />
                <!-- Btn -->
              </div>
            </div>

            <!-- Default btn -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Default</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Default')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div>
                <!-- Btn -->
                <v-btn class="default-btn">Action</v-btn>
                <!-- Btn -->
              </div>
            </div>

            <!-- Main action btn with icon -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Main action w/ Icon</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Main action w/ Icon')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div>
                <!-- Btn -->
                <ButtonWithIcon
                  class="main-action-icon-btn"
                  icon="mdi-account-plus"
                  :text="'Action'"
                  @click="btnClicked('Main action w/ Icon')"
                />
                <!-- Btn -->
              </div>
            </div>

            <!-- Main action btn -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Main action</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Main action')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div cols="auto">
                <!-- Btn -->
                <v-btn class="main-action-btn"> Action </v-btn>
                <!-- Btn -->
              </div>
            </div>

            <!-- Main action variant btn -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Main action variant</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Main action variant')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div cols="auto">
                <!-- Btn -->
                <v-btn class="main-action-variant-btn"> Action </v-btn>
                <!-- Btn -->
              </div>
            </div>

            <!-- Cancel btn -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Cancel</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Cancel')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div cols="auto">
                <!-- Btn -->
                <v-btn class="cancel-outlined-btn"> Action </v-btn>
                <!-- Btn -->
              </div>
            </div>

            <!-- Cancel Variant btn -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Cancel Variant</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Cancel Variant')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div cols="auto">
                <!-- Btn -->
                <v-btn class="cancel-variant-btn"> Action </v-btn>
                <!-- Btn -->
              </div>
            </div>

            <!-- Reject / Cancel btn -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Reject / Cancel</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Reject / Cancel')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div cols="auto">
                <!-- Btn -->
                <v-btn class="reject-cancel"> Reject / Cancel </v-btn>
                <!-- Btn -->
              </div>
            </div>

            <!-- Icon button btn -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Icon button</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Icon button')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div cols="auto">
                <!-- Btn -->
                <IconButton class="icon-btn" icon="mdi-dots-vertical" />
                <!-- Btn -->
              </div>
            </div>

            <!-- upload file btn -->
            <div
              class="d-flex flex-column btn-container py-4 my-2"
              align="center"
            >
              <div class="d-flex justify-center">
                <p class="mt-2 mr-2 style-label">Upload file</p>
                <v-btn
                  icon
                  color="caribbeanDark"
                  @click="copyToClipboard('Upload file')"
                >
                  <v-icon>mdi-code-tags</v-icon>
                </v-btn>
              </div>
              <div cols="auto">
                <!-- Btn -->
                <v-btn class="upload-file-btn"> Upload file </v-btn>
                <!-- Btn -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import ButtonWithIcon from '@/components/shared/customComponents/buttons/button-with-icon.vue';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';

export default {
  name: 'ButtonWiki',
  components: {
    ButtonWithIcon,
    IconButton,
  },

  methods: {
    selectedComponentHandler(name) {
      let selectedComponent = null;

      switch (name) {
        case 'Default w/ Icon':
          selectedComponent = `<ButtonWithIcon
              class="default-btn"
              icon="mdi-account-plus"
              :text="'Action'"
              @click="btnClicked()"
            />`;
          break;
        case 'Default':
          selectedComponent = `<v-btn class="default-btn">Action</v-btn>`;
          break;
        case 'Main action w/ Icon':
          selectedComponent = `<ButtonWithIcon
              class="main-action-icon-btn"
              icon="mdi-account-plus"
              :text="'Action'"
              @click="btnClicked()"
            />`;
          break;
        case 'Main action':
          selectedComponent = `<v-btn class="main-action-btn"> Action </v-btn>`;
          break;
        case 'Main action variant':
          selectedComponent = `<v-btn class="main-action-variant-btn"> Action </v-btn>`;
          break;
        case 'Cancel':
          selectedComponent = `<v-btn class="cancel-outlined-btn"> Action </v-btn>`;
          break;
        case 'Cancel Variant':
          selectedComponent = `<v-btn class="cancel-variant-btn"> Action </v-btn>`;
          break;
        case 'Reject / Cancel':
          selectedComponent = `<v-btn class="reject-cancel"> Reject / Cancel </v-btn>`;
          break;
        case 'Icon button':
          selectedComponent = `<IconButton class="icon-btn" icon="mdi-dots-vertical" />`;
          break;
        case 'Upload file':
          selectedComponent = `<v-btn class="upload-file-btn"> Upload a file </v-btn>`;
          break;
        case 'Main action disabled':
          selectedComponent = `<v-btn class="main-action-btn" disabled> Action </v-btn>`;
          break;
      }
      return selectedComponent;
    },

    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = this.selectedComponentHandler(name);
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },

    btnClicked(name) {
      console.log(`${name} was clicked!`);
    },
  },
};
</script>

<style scoped lang="scss">
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}

.btn-container {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
  width: 200px;
}

.style-label {
  font-size: 13px !important;
}
</style>
